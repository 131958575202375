.icon {
  margin-right: 20px;
}

.list {
  display: flex;
  flex: none;
  list-style: none;
  margin: 0;
}

.verticalList {
  flex-direction: column;

  .icon {
    margin: 0 auto 30px;
  }
}