.table,
.th,
.td,
.resultMobile {
  border: 2px solid var(--color-grey);
  border-collapse: collapse;
}

.resultMobile + .resultMobile {
	border-left: 0;
}

.table {
	width: 100%;
}

.supportBlock {
	padding: 40px 24px;
	vertical-align: bottom;
}

.supportBlockMobile {
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	padding: 40px 24px;
}
