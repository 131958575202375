@font-face {
  font-display: swap;
  font-family: 'e-Ukraine';
  font-style: normal;
  font-weight: 100;
  src:
    url('e-Ukraine-Thin.woff2') format('woff2'),
    url('e-Ukraine-Thin.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'e-Ukraine';
  font-style: normal;
  font-weight: 200;
  src:
    url('e-Ukraine-UltraLight.woff2') format('woff2'),
    url('e-Ukraine-UltraLight.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'e-Ukraine';
  font-style: normal;
  font-weight: 300;
  src:
    url('e-Ukraine-Light.woff2') format('woff2'),
    url('e-Ukraine-Light.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'e-Ukraine';
  font-style: normal;
  font-weight: 400;
  src:
    url('e-Ukraine-Regular.woff2') format('woff2'),
    url('e-Ukraine-Regular.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'e-Ukraine';
  font-style: normal;
  font-weight: 500;
  src:
    url('e-Ukraine-Medium.woff2') format('woff2'),
    url('e-Ukraine-Medium.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'e-Ukraine';
  font-style: normal;
  font-weight: 700;
  src:
    url('e-Ukraine-Bold.woff2') format('woff2'),
    url('e-Ukraine-Bold.woff') format('woff');
}
